/**
 * @docs https://github.com/validatorjs/validator.js
 */
import isEmptyLib from 'validator/es/lib/isEmpty';
import isEmailLib from 'validator/es/lib/isEmail';
import isLengthLib from 'validator/es/lib/isLength';
import equalsLib from 'validator/es/lib/equals';

import { isBoolean } from 'lodash-es';

/**
 * Using own validator so that we have simplified validator
 * These are called dynamically from the `ruleFunction.call()` function.
 *
 * @note Cannot use arrow function for anonymouse function here because
 * arrow functions won't take the this scope in function.call(this, param)
 *
 * Each validator should return either Boolean or Promise types.
 * @return {Boolean|Promise} Boolean will be resolved immediately. Promise will be resolved asynchronously.
 */
const isRequired = function(fieldValue = '') {
  return !isEmptyLib(fieldValue);
};

const isEmail = function(fieldValue = '') {
  return isEmailLib(fieldValue);
};

function minLength(length) {
  return function(fieldValue = '') {
    return isLengthLib(fieldValue, { min: length });
  };
}

function sameAs(comparedToFieldName) {
  return function(fieldValue = '') {
    const comparedToFieldValue =
      this.getField(comparedToFieldName)?.value || '';
    return equalsLib(fieldValue, comparedToFieldValue);
  };
}

function minLowerCase(length = 1) {
  return function(fieldValue = '') {
    return fieldValue?.match(/([a-z])/g)?.length >= length || false;
  };
}

function minUpperCase(length = 1) {
  return function(fieldValue = '') {
    return fieldValue?.match(/([A-Z])/g)?.length >= length || false;
  };
}

function minNumbers(length = 1) {
  return function(fieldValue = '') {
    return fieldValue?.match(/([0-9])/g)?.length >= length || false;
  };
}

/**
 * @Validators
 * Import these to use when initializing validation for the forms
 */
export {
  isRequired,
  isEmail,
  minLength,
  sameAs,
  minLowerCase,
  minUpperCase,
  minNumbers
};

function checkSettings(settings) {
  const settingSpec = {
    /**
     * Selector for the form.
     * @return {string}
     */
    form: 'string',

    /**
     * Set the rules of the validator.
     * @example rules: { password: { minLength: minLength(5) }}
     * @return {object}
     */
    rules: 'object',

    /**
     * Set the error messages of the validator.
     * @example messages: { password: { minLength: 'Password should have 5 or more chars' } }}
     * @return {object}
     */
    messages: 'object',

    /**
     * Create the error element.
     * @example return parseHTML(`<div>${errorMessage}</div>`)
     * @param {function} parseHTML HTML parser function
     * @param {object} field The field validation property
     * @return {node}
     */
    errorElement: 'function',

    /**
     * Position the error element relative to the input field.
     * @param {node} errorEl HTML element of the error component
     * @param {object} field The field validation property
     * @return {void}
     */
    errorPlacement: 'function',

    /**
     * Handler when someone presses the submit button.
     * @param {node} form
     * @return {void}
     */
    onSubmit: 'function'
  };

  const optionalSettings = ['onValidate'];

  Object.keys(settingSpec).forEach(setting => {
    if (!Object.keys(this.settings).includes(setting)) {
      if (optionalSettings.includes(setting)) return;

      throw Error(`Setting [${setting}] is required.`);
    }

    if (typeof this.settings[setting] !== settingSpec[setting]) {
      throw Error(
        `Setting [${setting}] must be [${settingSpec[setting]}] type.`
      );
    }
  });
}

function findElements() {
  const { form, rules } = this.settings;

  this.form = document.querySelector(form);
  if (!this.form) throw Error(`Cannot find form using selector ${form}`);

  Object.keys(rules).forEach(fieldName => {
    const fieldEl = this.form.querySelector(`[name=${fieldName}]`);
    if (!fieldEl) throw Error(`Cannot find field with name: ${fieldName}.`);

    this.fields[fieldName] = {
      name: fieldName,
      element: fieldEl,
      value: fieldEl.value,
      isDirty: false,
      isValid: false,
      errorMessage: '',
      errorEl: undefined,
      validation: {}
    };
  });
}

function attachListeners() {
  this.form.addEventListener('submit', handleFormSubmit.bind(this));

  Object.keys(this.fields).forEach(fieldName => {
    const field = this.getField(fieldName);
    const fieldEl = field.element;
    fieldEl.addEventListener('input', handleInputEvent.bind(this));
    fieldEl.addEventListener('blur', handleBlurEvent.bind(this));
  });
}

function handleInputEvent(e) {
  const value = e.target.value;
  const fieldName = e.target.getAttribute('name');
  const field = this.getField(fieldName);
  field.value = value;

  if (field.isDirty) {
    this.touchField(fieldName);
    this.validateAllFields();
  }
}

function handleBlurEvent(e) {
  const fieldName = e.target.getAttribute('name');
  this.touchField(fieldName);
  this.validateAllFields();
}

function handleFormSubmit(e) {
  e.preventDefault();

  this.touchAllFields();
  this.validateAllFields();

  if (this.isValid) {
    this.settings.onSubmit(e.target);
  }
}

/**
 * This needs to work on the first failed validation.
 * Needs to return a default error also if settings.messages is not available.
 *
 * @param {String} fieldName
 * @returns {String} Error message or empty string if valid.
 */
function getErrorMessage(fieldName) {
  const { messages } = this.settings;
  const field = this.getField(fieldName);

  if (field.isValid) {
    return '';
  } else {
    const failedRule = Object.entries(field.validation).find(
      ([ruleName, isValid]) => !isValid
    );

    const ruleName = failedRule[0];
    return messages[fieldName]?.[ruleName] || 'This field is invalid';
  }
}

function isPromise(variable) {
  return typeof variable === 'object' && 'then' in variable;
}

/**
 * @param {String} html
 * @returns {Node} Element
 */
function parseHTML(html) {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild;
}

function renderErrorElement(field) {
  // Removes existing error element first.
  if (field.errorEl) {
    field.errorEl.parentNode.removeChild(field.errorEl);
    field.errorEl = undefined;
  }

  // Create DOM
  if (field.isDirty && !field.isValid) {
    field.errorEl = this.settings.errorElement(parseHTML, field);
    this.settings.errorPlacement(field.errorEl, field);
  }
}

class FormValidator {
  /**
   * @param {Node} formEl
   * @param {object} settings
   * @returns {Class} form
   */
  constructor(settings) {
    this.fields = {};
    this.settings = settings;
    this.form = undefined;
    this.isDirty = false;
    this.isValid = false;

    try {
      checkSettings.call(this);
      findElements.call(this);
      attachListeners.call(this);

      return this;
    } catch (err) {
      console.error(err);
      return;
    }
  }

  getField(fieldName) {
    if (!fieldName in this.fields)
      throw Error(`Could not find field with name [${fieldName}].`);

    return this.fields[fieldName];
  }

  touchAllFields() {
    Object.keys(this.fields).map(fieldName => {
      this.touchField(fieldName, true);
    });
  }

  touchField(fieldName, forceTouch = false) {
    const field = this.getField(fieldName);

    if (field.value || forceTouch) {
      field.isDirty = true;
      this.isDirty = true;
    }
  }

  /**
   * Preferred so that the `sameAs` validation can be resolved properly with
   * both current and target fields.
   */
  validateAllFields() {
    Object.keys(this.fields).forEach(fieldName => {
      this.validateField(fieldName);
    });
  }

  validateField(fieldName) {
    const field = this.getField(fieldName);
    const fieldRules = this.settings.rules[fieldName];

    Object.entries(fieldRules).forEach(([ruleName, ruleFunction]) => {
      if (!ruleFunction) throw Error(`Rule function is not available.`);

      const validationResult = ruleFunction.call(this, field.value);

      if (isPromise(validationResult)) {
        validationResult.then(boolVal => {
          this.setFieldValidation(fieldName, ruleName, boolVal);
        });
      } else if (isBoolean(validationResult)) {
        this.setFieldValidation(fieldName, ruleName, validationResult);
      } else {
        throw Error('Invalid type returned for validation result.');
      }
    });

    this.onValidate && this.onValidate(this);
  }

  setFieldValidation(fieldName, ruleName, boolVal) {
    const field = this.getField(fieldName);
    field.validation[ruleName] = boolVal;

    field.isValid = Object.values(field.validation).every(val => val === true);
    field.errorMessage = getErrorMessage.call(this, fieldName);
    this.isValid = Object.values(this.fields).every(
      field => field.isValid === true
    );

    renderErrorElement.call(this, field);
  }
}

export default FormValidator;
