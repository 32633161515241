import FormValidator, { isRequired } from './classes/FormValidator';

window.addEventListener('DOMContentLoaded', () => {
  const loginForm = new FormValidator({
    form: '[data-qa=form_login]',
    rules: {
      username: {
        isRequired
      },
      password: {
        isRequired
      }
    },
    messages: {
      username: {
        isRequired: 'Email address is required.'
      },
      password: {
        isRequired: 'Password is required.'
      }
    },
    errorElement: (parseHTML, field) => {
      return parseHTML(
        `<div class="c-form__field-error">${field.errorMessage}</div>`
      );
    },
    errorPlacement: (error, field) => {
      const formGroup = field.element.closest('.c-form__group');
      formGroup.insertBefore(error, formGroup.firstChild);
    },
    onSubmit: form => {
      form.submit();
    }
  });

  if (
    window.self !== window.top && // In an iframe
    document.hasStorageAccess &&
    document.requestStorageAccess
  ) {
    getStorageAccess();
  }
});

/**
 * Safari requires `requestStorageAccess` before cookie can be stored through
 * the iframe (BIM360 & Procore integration).
 *
 * @docs https://webkit.org/blog/8124/introducing-storage-access-api/
 * @important https://stackoverflow.com/a/61532590
 */
function getStorageAccess() {
  const itpButton = document.getElementById('itp-button');
  const itpOverlay = document.getElementById('itp-overlay');
  const itpMessage = document.getElementById('itp-message');

  document.hasStorageAccess().then(hasAccess => {
    if (hasAccess) {
      console.info('Has storage access, move along.');
    }

    if (!hasAccess) {
      console.info('No storage access yet.');
      itpOverlay.classList.remove('hidden');
    }
  });

  itpButton.addEventListener('click', enableSafariIframeLogin);

  function enableSafariIframeLogin() {
    document.requestStorageAccess().then(
      () => {
        console.info('Access Granted.');
        itpOverlay.classList.add('hidden');
      },
      () => {
        // User pressed "not allowed", OR
        // Browser hasn't registered first party cookie from login URL before, so log in first.
        console.info('Access Denied (Or automatically denied).');
        itpMessage.classList.remove('hidden');
      }
    );
  }
}
